import React, { useReducer, useContext, useEffect } from "react";
import axios from "axios";
import AuthContext from "./authContext";
import authReducer from "./authReducer";
import setAuthToken from "../../utils/setAuthToken";
import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGOUT,
  ADMIN_REGISTER_REQUEST,
  ADMIN_REGISTER_SUCCESS,
  ADMIN_REGISTER_FAIL,
  ADMIN_DETAILS_REQUEST,
  ADMIN_DETAILS_SUCCESS,
  ADMIN_DETAILS_FAIL,
  ADMIN_DETAILS_RESET,
  ADMIN_UPDATE_PROFILE_REQUEST,
  ADMIN_UPDATE_PROFILE_SUCCESS,
  ADMIN_UPDATE_PROFILE_FAIL,
  ADMIN_UPDATE_PROFILE_RESET
} from "../constants/adminConstants";

import uri from "../../utils/uri";

// Logout
export const logout = dispatch => {
  dispatch({ type: ADMIN_LOGOUT });
};

// AuthState Provider Component

const AuthState = props => {
  const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: null,
    adminLoading: false,
    admin: null,
    adminRegister: null,
    error: null
  };

  const [state, dispatch] = useReducer(authReducer, initialState);
  const loadUser = async () => {
    setAuthToken(localStorage.token);
    try {
      dispatch({
        type: ADMIN_DETAILS_REQUEST
      });
      const { data } = await axios.get(`${uri}/api/v1/admin/auth`);
      dispatch({
        type: ADMIN_DETAILS_SUCCESS,
        payload: data
      });
    } catch (err) {
      dispatch({ type: ADMIN_DETAILS_FAIL });
    }
  };

  const loginESign = token => {
    dispatch({
      type: ADMIN_LOGIN_SUCCESS,
      payload: token
    });
    // loadUser();
  };
  const logout = () => {
    dispatch({ type: ADMIN_LOGOUT });
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        adminLoading: state.userLoading,
        admin: state.admin,
        adminRegister: state.adminRegister,
        error: state.error,
        loginESign,
        loadUser,
        logout
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
