import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Modal from "react-modal";
import { Menu } from "../../../components/Menu/Menu";
import Header from "../../../components/Header/Header";
import configAuthPost from "../../../utils/configAuthPost";
import configDelete from "../../../utils/configDelete";
import configAuthPut from "../../../utils/configAuthPut";
import uri from "../../../utils/uri";
import AuthContext from "../../../context/auth/authContext";
import { Watch } from "react-loader-spinner";
import { useAlert } from "react-alert";
import Content from "../../../components/Control/Content";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px"
  }
};

const Instaction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const alert = useAlert();
  const authContext = useContext(AuthContext);
  const { token } = authContext;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [instractions, setInstractions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [instractionCurrent, setInstractionCurrent] = useState({
    id: "",
    category: "",
    subcategory: "",
    text: "",
    link: "",
    file: ""
  });

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [select, setSelect] = useState(location.pathname.split("/")[3]);

  const getCategories = async () => {
    try {
      const response = await fetch(`${uri}/api/v1/admin/get/categories`);

      if (response.status === 200) {
        const json = await response.json();

        if (Array.isArray(json)) setCategories(json);
      }
    } catch (error) {}
  };

  const getSubcategories = async () => {
    try {
      const response = await fetch(`${uri}/api/v1/admin/get/subcategories`);

      if (response.status === 200) {
        const json = await response.json();

        if (Array.isArray(json)) setSubCategories(json);
      }
    } catch (error) {}
  };

  const getInstractions = async () => {
    try {
      const response = await fetch(`${uri}/api/v1/admin/get/instractions`);

      if (response.status === 200) {
        const json = await response.json();

        if (Array.isArray(json)) setInstractions(json);
      }
    } catch (error) {}
  };

  const updateInstraction = async e => {
    try {
      e.preventDefault();
      setLoading(true);
      if (!instractionCurrent.text)
        return alert.show("Заполните поле сообщение от специалиста", { closeCopy: "OK" });
      const response = await fetch(
        `${uri}/api/v1/admin/edit/instraction/${instractionCurrent.id}`,
        configAuthPut(instractionCurrent, token)
      );
      if (response.status === 200) {
        alert.show("Инструкция обновлена!", { closeCopy: "OK" });
        getInstractions();
        closeModal();
      }
    } catch (error) {
      alert.show(`Произошла ошибка, ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const deleteInstraction = async id => {
    try {
      if (loading) return;
      setLoading(true);
      const response = await fetch(
        `${uri}/api/v1/admin/delete/instraction/${id}`,
        configDelete(token)
      );
      if (response.status === 200) {
        const json = await response.json();
        if (json.msg === "Instraction removed") {
          alert.show("Инстуркция удалена!", { closeCopy: "OK" });
          getInstractions();
          closeModal();
        }
      }
    } catch (error) {
      alert.show("Произошла ошибка при удалении!", { closeCopy: "OK" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInstractions();
    getCategories();
    getSubcategories();
  }, []);

  return (
    <>
      <Header />
      <main style={{ paddingTop: "38px" }}>
        <div className="container" style={{ display: "flex", paddingBottom: "20px" }}>
          <Menu menuName="control" />
          <div
            className="right-panel"
            style={{
              backgroundColor: "#fff",
              borderRadius: "5px",
              width: "100%",
              position: "relative"
            }}
          >
            <div>
              <div style={{ paddingBottom: "70px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", padding: "18px" }}>
                  <select
                    style={{
                      border: "none",
                      outline: "none",
                      backgroundColor: "#eaeffd",
                      padding: "10px",
                      borderRadius: "5px"
                    }}
                    onChange={e => {
                      navigate(`/admin/control/${e.target.value}`);
                    }}
                  >
                    <option value="instraction">Инструкции</option>
                    <option value="category">Категории</option>
                    <option value="subcategory">Подкатегории</option>
                  </select>

                  <div
                    style={{
                      backgroundColor: "#28aeda",
                      borderRadius: "5px",
                      color: "#fff",
                      fontSize: "12.5px",
                      fontWeight: 500,
                      height: "33px",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingBottom: "5px",
                      paddingTop: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      setSelect("instraction");
                      openModal();
                    }}
                  >
                    Добавить инстукцию
                  </div>
                </div>
                {/* Таблица инструкция начинается */}
                {select === "instraction" || "editInstraction" ? (
                  <table>
                    <thead>
                      <tr style={{ borderLeft: "none", borderRight: "none" }}>
                        <th>№</th>
                        <th>Категория</th>
                        <th>Подкатегория</th>
                        <th>Инструкция</th>
                      </tr>
                    </thead>
                    <tbody>
                      {instractions.map((item, i) => (
                        <tr
                          key={item._id}
                          onClick={() => {
                            setSelect("editinstraction");
                            setInstractionCurrent({
                              id: item._id,
                              category: item.category,
                              subcategory: item.subcategory,
                              text: item.text ? item.text : "",
                              link: item.link ? item.link : "",
                              file: item.file ? item.file : ""
                            });
                            openModal();
                          }}
                        >
                          <td style={{ paddingTop: "20px" }}>{i + 1}</td>
                          <td style={{ paddingTop: "20px" }}>{item.category}</td>
                          <td style={{ paddingTop: "20px" }}>{item.subcategory}</td>
                          <td style={{ paddingTop: "20px" }}>{item.text}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  ""
                )}
                {/* Таблица инструкция заканчивается */}

                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    borderTopColor: "#e5eaeb",
                    borderTopWidth: "1px",
                    borderTopStyle: "solid"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingTop: "11px",
                      paddingBottom: "11px",
                      paddingLeft: "19px",
                      paddingRight: "19px",
                      alignItems: "center"
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ color: "#75787d", fontSize: "11.25px", marginRight: "8px" }}>
                        Страница
                      </div>
                      <div
                        style={{
                          borderColor: "#eaeeef",
                          borderWidth: "1px",
                          borderStyle: "solid",
                          fontSize: "11.25px",
                          paddingLeft: "13px",
                          paddingRight: "13px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          color: "#000"
                        }}
                      >
                        1
                      </div>
                      <div style={{ fontSize: "11.25px", marginLeft: "6px", color: "#75787d" }}>
                        из
                      </div>
                      <div style={{ fontSize: "11.25px", marginLeft: "6px", color: "#75787d" }}>
                        11
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Right panel ends */}
          </div>
        </div>
      </main>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <div style={{ minWidth: "328px", position: "relative" }}>
          <div
            style={{ position: "absolute", right: "16px", top: "16px", cursor: "pointer" }}
            onClick={closeModal}
          >
            <img src="/img/close.png" alt="close" />
          </div>

          {/* Добавить инструкцию модуль */}
          {select === "instraction" && (
            <Content getInstractions={getInstractions} closeModal={closeModal} />
          )}
          {/* Добавить инструкцию конец модуль */}
          {/* Добавить инструкцию модуль */}
          {select === "editinstraction" && (
            <div style={{ padding: "19px" }}>
              <div style={{ fontSize: "11.25px", color: "#7f7d76" }}>Категория</div>
              <form onSubmit={updateInstraction}>
                <select
                  style={{
                    width: "100%",
                    backgroundColor: "#f4f6f9",
                    border: "none",
                    padding: "10px",
                    borderRadius: "5px",
                    marginTop: "6px"
                  }}
                  onChange={e =>
                    setInstractionCurrent({ ...instractionCurrent, category: e.target.value })
                  }
                  value={instractionCurrent.category}
                >
                  {categories.map(item => (
                    <option key={item._id} value={item.category}>
                      {item.category}
                    </option>
                  ))}
                </select>
                <div style={{ fontSize: "11.25px", color: "#7f7d76", marginTop: "25px" }}>
                  Подкатегория
                </div>
                <select
                  style={{
                    width: "100%",
                    backgroundColor: "#f4f6f9",
                    border: "none",
                    padding: "10px",
                    borderRadius: "5px",
                    marginTop: "6px"
                  }}
                  onChange={e =>
                    setInstractionCurrent({ ...instractionCurrent, subcategory: e.target.value })
                  }
                  value={instractionCurrent.subcategory}
                >
                  {subCategories
                    .filter(item => item.category === instractionCurrent.category)
                    .map(item => (
                      <option key={item._id} value={item.subcategory}>
                        {item.subcategory}
                      </option>
                    ))}
                </select>
                <div
                  style={{
                    fontSize: "11.25px",
                    color: "#7f7d76",
                    marginTop: "14px",
                    marginBottom: "14px"
                  }}
                >
                  Текст инструкции
                </div>
                <div>
                  <textarea
                    style={{
                      resize: "none",
                      height: "122px",
                      borderColor: "#e0eff9",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      padding: "5px",
                      width: "100%"
                    }}
                    value={instractionCurrent.text}
                    onChange={e =>
                      setInstractionCurrent({ ...instractionCurrent, text: e.target.value })
                    }
                    required
                  ></textarea>
                </div>
                <div
                  style={{
                    fontSize: "11.25px",
                    color: "#7f7d76",
                    marginTop: "14px",
                    marginBottom: "8px"
                  }}
                >
                  Ссылка, если есть
                </div>
                <div>
                  {instractionCurrent.link && (
                    <input
                      type="text"
                      style={{
                        backgroundColor: "#f4f6f9",
                        borderRadius: "5px",
                        padding: "5px",
                        fontSize: "11.25px",
                        marginBottom: "20px"
                      }}
                      value={instractionCurrent.link}
                      onChange={e =>
                        setInstractionCurrent({ ...instractionCurrent, link: e.target.value })
                      }
                    />
                  )}
                </div>

                {instractionCurrent.file && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "12px"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        fontSize: "12.5px",
                        fontWeight: 500,
                        alignItems: "center",
                        maxWidth: "250px"
                      }}
                    >
                      <div style={{ marginRight: "10px" }}>
                        <img src="/img/download-icon.png" alt="file" />
                      </div>{" "}
                      {instractionCurrent.file.split("/")[6]}
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setInstractionCurrent({ ...instractionCurrent, file: "" })}
                    >
                      <img src="/img/cancel.png" alt="cancel" />
                    </div>
                  </div>
                )}
                <div style={{ display: "flex", alignItems: "center", paddingTop: "30px" }}>
                  <button
                    style={{
                      marginRight: "17px",
                      color: "#fff",
                      backgroundColor: "#7db766",
                      borderRadius: "5px",
                      height: "24px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      fontSize: "12.5px"
                    }}
                    type="submit"
                  >
                    Сохранить
                  </button>
                  <div
                    style={{ fontSize: "12.5px", cursor: "pointer" }}
                    onClick={() => {
                      deleteInstraction(instractionCurrent.id);
                    }}
                  >
                    Удалить
                  </div>
                </div>
              </form>
            </div>
          )}
          {/* Добавить инструкцию конец модуль */}
        </div>
      </Modal>
    </>
  );
};

export default Instaction;
