import { useContext, useState, useEffect } from "react";
import { Menu } from "../../components/Menu/Menu";
import AuthContext from "../../context/auth/authContext";
import Header from "../../components/Header/Header";
import uri from "../../utils/uri";

const Analytics = () => {
  // analytics, announcements, workers
  const [analitica, setAnalitica] = useState({
    allApplies: 0,
    opened: 0,
    inWork: 0,
    closedApplies: 0,
    result: []
  });
  const { allApplies, opened, inWork, closedApplies, result } = analitica;

  const getAnalytics = async () => {
    try {
      const response = await fetch(`${uri}/api/v1/analytics`);

      if (response.status === 200) {
        const json = await response.json();

        if (json.result) setAnalitica(json);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAnalytics();
  }, []);

  return (
    <>
      <Header />
      <main style={{ paddingTop: "38px" }}>
        <div className="container" style={{ display: "flex", paddingBottom: "20px" }}>
          <Menu menuName="analytics" />
          <div
            className="right-panel"
            style={{ backgroundColor: "#fff", borderRadius: "5px", width: "100%" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingBottom: "20px",
                alignItems: "center"
              }}
            >
              <div style={{ fontWeight: "700", fontSize: "16.67px" }}>Аналитика по обращениям</div>
              <select
                name=""
                id=""
                style={{
                  minWidth: "128.31px",
                  borderRadius: "5px",
                  backgroundColor: "#eaeffd",
                  border: "none",
                  padding: "10px"
                }}
              >
                <option value="2024">2024 год</option>
                {/* <option value="2025">2025 год</option> */}
              </select>
            </div>
            <div
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingBottom: "25px",
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <div
                style={{
                  width: "237px",
                  backgroundColor: "#602ba8",
                  padding: "20px",
                  borderRadius: "5px"
                }}
              >
                <div style={{ fontSize: "12.5px", color: "#fff", paddingBottom: "20px" }}>
                  Всего обращений
                </div>
                <div style={{ fontSize: "41px", fontWeight: "700", color: "#fff" }}>
                  {allApplies}
                </div>
              </div>
              <div
                style={{
                  width: "237px",
                  backgroundColor: "#28aeda",
                  padding: "20px",
                  borderRadius: "5px"
                }}
              >
                <div style={{ fontSize: "12.5px", color: "#fff", paddingBottom: "20px" }}>
                  Открытых обращений
                </div>
                <div style={{ fontSize: "41px", fontWeight: "700", color: "#fff" }}>{opened}</div>
              </div>
              <div
                style={{
                  width: "237px",
                  backgroundColor: "#df8f2f",
                  padding: "20px",
                  borderRadius: "5px"
                }}
              >
                <div style={{ fontSize: "12.5px", color: "#fff", paddingBottom: "20px" }}>
                  Обращений в работе
                </div>
                <div style={{ fontSize: "41px", fontWeight: "700", color: "#fff" }}>{inWork}</div>
              </div>
              <div
                style={{
                  width: "237px",
                  backgroundColor: "#73b47a",
                  padding: "20px",
                  borderRadius: "5px"
                }}
              >
                <div style={{ fontSize: "12.5px", color: "#fff", paddingBottom: "20px" }}>
                  Закрытых обращений
                </div>
                <div style={{ fontSize: "41px", fontWeight: "700", color: "#fff" }}>
                  {closedApplies}
                </div>
              </div>
            </div>
            {/* <div
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                display: "flex",
                justifyContent: "flex-end",
                paddingBottom: "20px"
              }}
            >
              <select
                name=""
                id=""
                style={{
                  minWidth: "128.31px",
                  backgroundColor: "#eaeffd",
                  border: "none",
                  padding: "10px",
                  borderRadius: "5px"
                }}
                defaultValue={"DEFAULT"}
              >
                <option value="DEFAULT" disabled>
                  Выберите критерий
                </option>
                <option value="Исполнители">Исполнители</option>
                <option value="Категории">Категории</option>
                <option value="Подкатегории">Подкатегории</option>
              </select>
            </div> */}

            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
              <div style={{ paddingBottom: "70px" }}>
                <table>
                  <thead>
                    <tr>
                      <th>Рейтинг</th>
                      <th>Данные исполнителя</th>
                      <th>Всего обращений</th>
                      <th>Открытых обращений</th>
                      <th>Обращений в работе</th>
                      <th>Закрытых обращений</th>
                    </tr>
                  </thead>
                  <tbody>
                    {result.map((item, i) => (
                      <tr key={`${item.workerName} + ${Date.now()}`}>
                        <td style={{ paddingTop: "20px" }}>{i + 1}</td>
                        <td style={{ paddingTop: "20px" }}>{item.workerName}</td>
                        <td style={{ paddingTop: "20px" }}>{item.totalApplies}</td>
                        <td style={{ paddingTop: "20px" }}>{item.openedApplies}</td>
                        <td style={{ paddingTop: "20px" }}>{item.atWorkApplies}</td>
                        <td style={{ paddingTop: "20px" }}>{item.closedApplies}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* Конец первой таблицы */}
              <div style={{ paddingBottom: "70px", display: "none" }}>
                <table>
                  <tr>
                    <th>Рейтинг</th>
                    <th>Категория</th>
                    <th>Всего обращений</th>
                    <th>Открытых обращений</th>
                    <th>Обращений в работе</th>
                    <th>Закрытых обращений</th>
                  </tr>
                  <tr>
                    <td style={{ paddingTop: "20px" }}>1</td>
                    <td style={{ paddingTop: "20px" }}>Пригтер</td>
                    <td style={{ paddingTop: "20px" }}>17700</td>
                    <td style={{ paddingTop: "20px" }}>700</td>
                    <td style={{ paddingTop: "20px" }}>700</td>
                    <td style={{ paddingTop: "20px" }}>17000</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Пригтер</td>
                    <td>17700</td>
                    <td>700</td>
                    <td>700</td>
                    <td>17000</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Пригтер</td>
                    <td>17700</td>
                    <td>700</td>
                    <td>700</td>
                    <td>17000</td>
                  </tr>
                </table>
              </div>
              {/* Конец второй таблицы */}
              <div style={{ paddingBottom: "70px", display: "none" }}>
                <table>
                  <tr>
                    <th>Рейтинг</th>
                    <th>Подкатегория</th>
                    <th>Всего обращений</th>
                    <th>Открытых обращений</th>
                    <th>Обращений в работе</th>
                    <th>Закрытых обращений</th>
                  </tr>
                  <tr>
                    <td style={{ paddingTop: "20px" }}>1</td>
                    <td style={{ paddingTop: "20px" }}>Пригтер</td>
                    <td style={{ paddingTop: "20px" }}>17700</td>
                    <td style={{ paddingTop: "20px" }}>700</td>
                    <td style={{ paddingTop: "20px" }}>700</td>
                    <td style={{ paddingTop: "20px" }}>17000</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Пригтер</td>
                    <td>17700</td>
                    <td>700</td>
                    <td>700</td>
                    <td>17000</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Пригтер</td>
                    <td>17700</td>
                    <td>700</td>
                    <td>700</td>
                    <td>17000</td>
                  </tr>
                </table>
              </div>
            </div>
            {/* Right panel ends */}
          </div>
        </div>
      </main>
    </>
  );
};

export default Analytics;
