const configAuthPut = (fields, token) => {
  const config = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(fields)
  };

  return config;
};

export default configAuthPut;
