export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";

export const ADMIN_REGISTER_REQUEST = "ADMIN_REGISTER_REQUEST";
export const ADMIN_REGISTER_SUCCESS = "ADMIN_REGISTER_SUCCESS";
export const ADMIN_REGISTER_FAIL = "ADMIN_REGISTER_FAIL";

export const ADMIN_DETAILS_REQUEST = "ADMIN_DETAILS_REQUEST";
export const ADMIN_DETAILS_SUCCESS = "ADMIN_DETAILS_SUCCESS";
export const ADMIN_DETAILS_FAIL = "ADMIN_DETAILS_FAIL";
export const ADMIN_DETAILS_RESET = "ADMIN_DETAILS_RESET";

export const ADMIN_UPDATE_PROFILE_REQUEST = "ADMIN_UPDATE_PROFILE_REQUEST";
export const ADMIN_UPDATE_PROFILE_SUCCESS = "ADMIN_UPDATE_PROFILE_SUCCESS";
export const ADMIN_UPDATE_PROFILE_FAIL = "ADMIN_UPDATE_PROFILE_FAIL";
export const ADMIN_UPDATE_PROFILE_RESET = "ADMIN_UPDATE_PROFILE_RESET";
