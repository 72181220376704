import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import Modal from "react-modal";
import { Menu } from "../../components/Menu/Menu";
import Header from "../../components/Header/Header";
import AuthContext from "../../context/auth/authContext";
import uri from "../../utils/uri";
import moment from "moment";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px"
  }
};

const Announcements = () => {
  const [applies, setApplies] = useState([]);
  const alert = useAlert();
  let { pageNumber } = useParams();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { token } = authContext;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState("");
  const [currentPage, setCurrentPage] = useState(pageNumber);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [paginationArray, setPaginationArray] = useState([]);
  const [filter, setFilter] = useState("");

  // analytics, announcements, workers
  const [tab, setTab] = useState("analytics");
  const changeTab = tab => {
    setTab(tab);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // const getApplies = async pageNumber => {
  //   try {
  //     const response = await fetch(`${uri}/api/v1/admin/get/applies/${pageNumber}`, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${token}`
  //       }
  //     });

  //     if (response.status === 200) {
  //       const json = await response.json();
  //       setApplies(json.data);
  //       setCurrentPage(json.currentPage);
  //       setNumberOfPages(json.numberOfPages);
  //       setPaginationArray(json.paginationArray);
  //     }
  //   } catch (error) {}
  // };
  const getApplies = async (pageNumber, filterStatus = filter) => {
    try {
      const queryString = filterStatus ? `?status=${filterStatus}` : "";
      const response = await fetch(`${uri}/api/v1/admin/get/applies/${pageNumber}${queryString}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        const json = await response.json();
        setApplies(json.data);
        setCurrentPage(json.currentPage);
        setNumberOfPages(json.numberOfPages);
        setPaginationArray(json.paginationArray);
      }
    } catch (error) {
      alert.error("Error fetching applies");
    }
  };

  const openApply = item => {
    setCurrent(item);
    openModal();
  };

  const acceptToWork = async () => {
    try {
      const response = await fetch(`${uri}/api/v1/admin/accept/apply/${current._id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        alert.show("Обращение принято в работу!", { closeCopy: "OK" });
        getApplies();
        closeModal();
      }
    } catch (error) {}
  };

  const closeApply = async () => {
    try {
      const response = await fetch(`${uri}/api/v1/admin/close/apply/${current._id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        alert.show("Обращение закрыто!", { closeCopy: "OK" });
        getApplies();
        closeModal();
      }
    } catch (error) {}
  };

  // const reducePagination = () => {
  //   const newPage = Math.max(1, currentPage - 1);
  //   if (currentPage !== newPage) {
  //     navigate(`/admin/announcements/${newPage}`);
  //   }
  // };

  // const increasePagination = () => {
  //   const newPage = Math.min(numberOfPages, currentPage + 1);
  //   if (currentPage !== newPage) {
  //     navigate(`/admin/announcements/${newPage}`);
  //   }
  // };

  const reducePagination = () => {
    const newPage = Math.max(1, parseInt(currentPage, 10) - 1);
    if (currentPage !== newPage) {
      navigate(`/admin/announcements/${newPage}?filter=${filter}`);
    }
  };

  const increasePagination = () => {
    const newPage = Math.min(numberOfPages, parseInt(currentPage, 10) + 1);
    if (currentPage !== newPage) {
      navigate(`/admin/announcements/${newPage}?filter=${filter}`);
    }
  };
  // useEffect(() => {
  //   getApplies(pageNumber);
  // }, [pageNumber]);
  useEffect(() => {
    getApplies(pageNumber);
  }, [pageNumber, filter]);

  useEffect(() => {
    // Set filter from URL on mount
    const searchParams = new URLSearchParams(window.location.search);
    const filterStatus = searchParams.get("filter");
    if (filterStatus) {
      setFilter(filterStatus);
    }
    getApplies(pageNumber, filterStatus);
  }, []);

  return (
    <>
      <Header />
      <main style={{ paddingTop: "38px" }}>
        <div className="container" style={{ display: "flex", paddingBottom: "20px" }}>
          <Menu menuName="announcements" />
          <div
            className="right-panel"
            style={{
              backgroundColor: "#fff",
              borderRadius: "5px",
              width: "100%",
              position: "relative"
            }}
          >
            <div>
              <div style={{ paddingBottom: "70px" }}>
                <table>
                  <thead>
                    <tr style={{ borderLeft: "none", borderRight: "none" }}>
                      <th>№ заявки</th>
                      {/* <th>
                      <select defaultValue={"DEFAULT"} style={{ border: "none", outline: "none" }}>
                        <option value="DEFAULT" disabled>
                          Категория
                        </option>
                        <option value="open">Открыты</option>
                        <option value="in work">В работе</option>
                        <option value="closed">Закрыты</option>
                      </select>
                    </th> */}
                      {/* <th>
                      <select defaultValue={"DEFAULT"} style={{ border: "none", outline: "none" }}>
                        <option value="DEFAULT" disabled>
                          Подкатегория
                        </option>
                        <option value="open">Открыты</option>
                        <option value="in work">В работе</option>
                        <option value="closed">Закрыты</option>
                      </select>
                    </th> */}
                      <th>Категория</th>
                      <th>Создано</th>
                      <th>Закрыто</th>
                      <th>
                        {/* <select
                          defaultValue={"DEFAULT"}
                          style={{ border: "none", outline: "none" }}
                        >
                          <option value="DEFAULT" disabled>
                            Исполнитель
                          </option>
                          <option value="open">Открыты</option>
                          <option value="in work">В работе</option>
                          <option value="closed">Закрыты</option>
                        </select> */}
                        Исполнитель
                      </th>
                      <th>
                        <select
                          defaultValue={"DEFAULT"}
                          style={{ border: "none", outline: "none" }}
                          onChange={e => setFilter(e.target.value)}
                        >
                          <option value="DEFAULT" disabled>
                            Статус
                          </option>
                          <option value="Открыт">Открыт</option>
                          <option value="В работе">В работе</option>
                          <option value="Закрыт">Закрыт</option>
                        </select>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {applies.map((item, i) => (
                      <tr key={item._id} onClick={() => openApply(item)}>
                        <td style={{ paddingTop: "20px" }}>{item.applyId ? item.applyId : ""}</td>
                        <td style={{ paddingTop: "20px" }}>{item.category ? item.category : ""}</td>
                        {/* <td style={{ paddingTop: "20px" }}>""</td>
                      <td style={{ paddingTop: "20px" }}>""</td> */}
                        <td style={{ paddingTop: "20px" }}>
                          {moment(item.createdAt).format("DD.MM.YYYY")}
                        </td>
                        {item.closedAt ? (
                          <td style={{ paddingTop: "20px" }}>
                            {moment(item.closedAt).format("DD.MM.YYYY")}
                          </td>
                        ) : (
                          <td style={{ paddingTop: "20px" }}>""</td>
                        )}
                        <td style={{ paddingTop: "20px" }}>
                          {item?.worker?.workerName ? item.worker.workerName : ""}
                        </td>
                        <td style={{ paddingTop: "20px" }}>
                          {item.status === "Открыт" ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "11.25px",
                                fontWeight: 700,
                                width: "70px",
                                justifyContent: "space-between"
                              }}
                            >
                              Открыт{" "}
                              <div
                                style={{
                                  width: "5px",
                                  height: "5px",
                                  backgroundColor: "#28aeda",
                                  borderRadius: "50%"
                                }}
                              ></div>
                            </div>
                          ) : item.status === "В работе" ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "11.25px",
                                fontWeight: 700,
                                width: "70px",
                                justifyContent: "space-between"
                              }}
                            >
                              В работе{" "}
                              <div
                                style={{
                                  width: "5px",
                                  height: "5px",
                                  backgroundColor: "#cda72a",
                                  borderRadius: "50%"
                                }}
                              ></div>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "11.25px",
                                fontWeight: 700,
                                width: "70px",
                                justifyContent: "space-between"
                              }}
                            >
                              Закрыт{" "}
                              <div
                                style={{
                                  width: "5px",
                                  height: "5px",
                                  backgroundColor: "#8fbe39",
                                  borderRadius: "50%"
                                }}
                              ></div>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {Number(numberOfPages) > 1 && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                      borderTopColor: "#e5eaeb",
                      borderTopWidth: "1px",
                      borderTopStyle: "solid"
                    }}
                  >
                    <div className="dashboard-paginations">
                      <div className="pagination-item" onClick={reducePagination}>
                        <img src="/img/arrow-left-pagination.png" alt="arrow-left" />
                      </div>
                      {paginationArray.map(item => (
                        <div
                          className={
                            item === currentPage
                              ? "pagination-item pagination-item__acitve"
                              : "pagination-item"
                          }
                          onClick={() => {
                            navigate(`/admin/announcements/${item}`);
                          }}
                          key={item.toString()}
                        >
                          {item}
                        </div>
                      ))}

                      <div className="pagination-item" onClick={increasePagination}>
                        <img src="/img/arrow-right-pagination.png" alt="arrow-right" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* Right panel ends */}
          </div>
        </div>
      </main>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <div style={{ minWidth: "754px", position: "relative" }}>
          <div
            style={{ position: "absolute", right: "16px", top: "16px", cursor: "pointer" }}
            onClick={closeModal}
          >
            <img src="/img/close.png" alt="close" />
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ padding: "30px", minWidth: "435px" }}>
              <div
                style={{
                  fontWeight: 700,
                  fontSize: "16.67px",
                  color: "#030303",
                  marginBottom: "13px"
                }}
              >
                Обращение № {current._id}
              </div>
              <div style={{ display: "flex", marginBottom: "23px" }}>
                <div
                  style={{
                    fontSize: "11.25px",
                    color: "#75787d",
                    borderRightColor: "#e5eaeb",
                    borderRightStyle: "solid",
                    borderRightWidth: "1px",
                    paddingRight: "15px",

                    paddingTop: "4px",
                    paddingBottom: "4px",
                    width: "min-content"
                  }}
                >
                  {current.branch}
                </div>
                <div
                  style={{
                    fontSize: "11.25px",
                    color: "#75787d",
                    borderRightColor: "#e5eaeb",
                    borderRightStyle: "solid",
                    borderRightWidth: "1px",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    width: "min-content"
                  }}
                >
                  {current.workerName}
                </div>
                <div
                  style={{
                    fontSize: "11.25px",
                    color: "#75787d",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    width: "min-content"
                  }}
                >
                  {current.workerPhone}
                </div>
              </div>
              <div style={{ fontSize: "13.75px", fontWeight: 700, marginBottom: "10px" }}>
                Текст обращения
              </div>
              <div style={{ fontSize: "12.5px", maxWidth: "414px" }}>{current.applyText}</div>
              <div
                style={{
                  fontSize: "13.75px",
                  fontWeight: 700,
                  marginTop: "30px",
                  marginBottom: "19px"
                }}
              >
                Файлы
              </div>
              {current.file && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "12.5px",
                    fontWeight: 500,
                    marginBottom: "12px",
                    cursor: "pointer"
                  }}
                  onClick={() => window.open(`${current.file}`)}
                >
                  <div style={{ marginRight: "13px" }}>
                    <img src="/img/download-icon.png" alt="download" />
                  </div>
                  Скачать файл
                </div>
              )}
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "12.5px",
                  fontWeight: 500,
                  marginBottom: "12px"
                }}
              >
                <div style={{ marginRight: "13px" }}>
                  <img src="/img/download-icon.png" alt="download" />
                </div>
                Название файла.jpeg
              </div> */}
            </div>
            {/* Конец первого div */}
            <div
              style={{
                backgroundColor: "#f6f6f6",
                width: "100%",
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "30px",
                paddingBottom: "30px"
              }}
            >
              {/* Div с исполнителем */}
              {current?.worker?.workerName && (
                <>
                  <div style={{ fontSize: "13.75px", color: "#000", marginBottom: "18px" }}>
                    Исполнитель
                  </div>
                  <div
                    style={{
                      backgroundColor: "#e5e4e4",
                      color: "#b2b5b6",
                      textAlign: "center",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "8px",
                      fontSize: "13.75px",
                      fontWeight: 500
                    }}
                  >
                    {current.worker.workerName}
                  </div>
                </>
              )}
              {current.status === "Открыт" && (
                <div style={{ fontSize: "13.75px", color: "#000", marginBottom: "18px" }}>
                  Выберите действие
                </div>
              )}
              {current.status === "Открыт" && (
                <div
                  style={{
                    backgroundColor: "#df8f2f",
                    color: "#fff",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "10px",
                    fontSize: "13.75px",
                    width: "213px",
                    cursor: "pointer"
                  }}
                  onClick={acceptToWork}
                >
                  Принять на работу
                </div>
              )}
              <div style={{ marginTop: "100px" }}>
                {current.status === "В работе" && (
                  <div
                    style={{
                      backgroundColor: "#74b47b",
                      color: "#fff",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      fontSize: "13.75px",
                      width: "213px",
                      cursor: "pointer"
                    }}
                    onClick={closeApply}
                  >
                    Закрыть обращение
                  </div>
                )}
                {current.status === "Закрыт" && (
                  <div
                    style={{
                      backgroundColor: "#e5e4e4",
                      color: "#b2b5b6",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      fontSize: "13.75px",
                      width: "213px"
                    }}
                  >
                    Обращение закрыто
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Announcements;
