import { useState, useEffect, useContext } from "react";
import { useAlert } from "react-alert";
import AuthContext from "../../context/auth/authContext";
import { Watch } from "react-loader-spinner";
import uri from "../../utils/uri";

const Content = ({ getInstractions, closeModal }) => {
  const alert = useAlert();
  const authContext = useContext(AuthContext);
  const { token } = authContext;
  const [addInstractionLoading, setAddInstractionLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [subcategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [text, setText] = useState("");
  const [link, setLink] = useState("");
  const [file, setFile] = useState("");

  const getCategories = async () => {
    try {
      const response = await fetch(`${uri}/api/v1/admin/get/categories`);

      if (response.status === 200) {
        const json = await response.json();

        if (Array.isArray(json)) setCategories(json);
      }
    } catch (error) {}
  };

  const getSubcategories = async () => {
    try {
      const response = await fetch(`${uri}/api/v1/admin/get/subcategories`);

      if (response.status === 200) {
        const json = await response.json();

        if (Array.isArray(json)) setSubCategories(json);
      }
    } catch (error) {}
  };

  const addInstraction = async e => {
    try {
      e.preventDefault();
      if (addInstractionLoading) return;
      if (!category) {
        return alert.show("Выберите категорию!", { closeCopy: "OK" });
      }
      if (!subcategory) {
        return alert.show("Выберите подкатегорию!", { closeCopy: "OK" });
      }
      if (!text) {
        return alert.show("Введите текст инструкции!", { closeCopy: "OK" });
      }
      setAddInstractionLoading(true);
      const formdata = new FormData();
      formdata.append("category", category);
      formdata.append("subcategory", subcategory);
      formdata.append("text", text);
      if (link) {
        formdata.append("link", link);
      }
      if (file) {
        formdata.append("file", file, file.name.trim());
      }
      const response = await fetch(`${uri}/api/v1/admin/add/instraction`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: formdata
      });
      if (response.status === 200) {
        alert.show("Инструкция добавлена!", { closeCopy: "OK" });
        getInstractions();
        closeModal();
      }
    } catch (error) {
      alert.show(`Произошла ошибка, во время добавления инструкции! ${error.message}`, {
        closeCopy: "OK"
      });
    } finally {
      setAddInstractionLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
    getSubcategories();
  }, []);

  return (
    <div style={{ padding: "19px" }}>
      <div style={{ fontSize: "11.25px", color: "#7f7d76" }}>Категория</div>
      <form onSubmit={addInstraction}>
        <select
          style={{
            width: "100%",
            backgroundColor: "#f4f6f9",
            border: "none",
            padding: "10px",
            borderRadius: "5px",
            marginTop: "6px"
          }}
          defaultValue={"DEFAULT"}
          onChange={e => {
            setCategory(e.target.value);
          }}
        >
          <option value="DEFAULT" disabled>
            Выберите категорию
          </option>
          {categories.map(item => (
            <option key={item.category} value={item.category}>
              {item.category}
            </option>
          ))}
        </select>
        <div style={{ fontSize: "11.25px", color: "#7f7d76", marginTop: "25px" }}>Подкатегория</div>
        <select
          style={{
            width: "100%",
            backgroundColor: "#f4f6f9",
            border: "none",
            padding: "10px",
            borderRadius: "5px",
            marginTop: "6px"
          }}
          defaultValue={"DEFAULT"}
          onChange={e => setSubcategory(e.target.value)}
        >
          <option value="DEFAULT" disabled>
            Выберите подкатегорию
          </option>
          {category.length > 0 &&
            subcategories
              .filter(item => item.category === category)
              .map(item => (
                <option key={item._id} value={item.subcategory}>
                  {item.subcategory}
                </option>
              ))}
        </select>
        <div
          style={{
            fontSize: "11.25px",
            color: "#7f7d76",
            marginTop: "14px",
            marginBottom: "14px"
          }}
        >
          Текст инструкции
        </div>
        <div>
          <textarea
            style={{
              resize: "none",
              height: "122px",
              borderColor: "#e0eff9",
              borderStyle: "solid",
              borderWidth: "1px",
              padding: "5px",
              width: "100%"
            }}
            onChange={e => setText(e.target.value)}
            value={text}
          ></textarea>
        </div>
        <div
          style={{
            fontSize: "11.25px",
            color: "#7f7d76",
            marginTop: "14px",
            marginBottom: "8px"
          }}
        >
          Ссылка, если есть
        </div>
        <div>
          <input
            type="text"
            style={{
              backgroundColor: "#f4f6f9",
              borderRadius: "5px",
              padding: "5px",
              fontSize: "11.25px"
            }}
            onChange={e => setLink(e.target.value)}
            value={link}
          />
        </div>
        <div
          style={{
            backgroundColor: "#eef4f8",
            width: "min-content",
            minWidth: "119px",
            fontSize: "11.25px",
            color: "#424346",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "18px",
            paddingTop: "7px",
            paddingBottom: "7px",
            borderRadius: "5px",
            cursor: "pointer",
            position: "relative"
          }}
        >
          {file ? (
            file.name
          ) : (
            <>
              <input
                type="file"
                style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, opacity: 0 }}
                onChange={e => setFile(e.target.files[0])}
              />
              Добавить файл
            </>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center", paddingTop: "30px" }}>
          <button
            style={{
              marginRight: "17px",
              color: "#fff",
              backgroundColor: "#7db766",
              borderRadius: "5px",
              height: "24px",
              paddingLeft: "10px",
              paddingRight: "10px",
              fontSize: "12.5px",
              minWidth: "77px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
            type="submit"
          >
            {addInstractionLoading ? (
              <Watch
                color="#fff"
                height={12}
                width={12}
                visible={true}
                style={{
                  display: "flex",
                  justifyContent: "center"
                }}
              />
            ) : (
              "Добавить"
            )}
          </button>
          <div style={{ fontSize: "12.5px", cursor: "pointer" }}>Отменить</div>
        </div>
      </form>
    </div>
  );
};

export default Content;
