import { useContext, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import AuthContext from "../context/auth/authContext";

const PrivateRoutes = () => {
  const authContext = useContext(AuthContext);
  const { token, loadUser, admin } = authContext;
  useEffect(() => {
    const check = async () => {
      if (token) {
        await loadUser();
      }
    };
    check();
  }, []);

  return admin?.role === "admin" ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
