import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGOUT,
  ADMIN_REGISTER_REQUEST,
  ADMIN_REGISTER_SUCCESS,
  ADMIN_REGISTER_FAIL,
  ADMIN_DETAILS_REQUEST,
  ADMIN_DETAILS_SUCCESS,
  ADMIN_DETAILS_FAIL,
  ADMIN_DETAILS_RESET,
  ADMIN_UPDATE_PROFILE_REQUEST,
  ADMIN_UPDATE_PROFILE_SUCCESS,
  ADMIN_UPDATE_PROFILE_FAIL,
  ADMIN_UPDATE_PROFILE_RESET
} from "../constants/adminConstants";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case ADMIN_REGISTER_REQUEST:
    case ADMIN_UPDATE_PROFILE_REQUEST:
    case ADMIN_LOGIN_REQUEST:
    case ADMIN_DETAILS_REQUEST: {
      return {
        ...state,
        adminLoading: true
      };
    }

    case ADMIN_DETAILS_SUCCESS:
      localStorage.setItem("user", JSON.stringify(action.payload));
      return {
        ...state,
        isAuthenticated: true,
        adminLoading: false,
        admin: action.payload
      };
    case ADMIN_REGISTER_SUCCESS:
      return {
        ...state,
        adminRegister: action.payload.success
      };
    case ADMIN_LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        adminLoading: false
      };
    case ADMIN_REGISTER_FAIL:
    case ADMIN_LOGIN_FAIL:
    case ADMIN_DETAILS_FAIL:
    case ADMIN_UPDATE_PROFILE_FAIL:
    case ADMIN_LOGOUT:
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        adminLoading: false,
        admin: null
      };
    case ADMIN_UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        adminLoading: false
      };
    default:
      return state;
  }
};
