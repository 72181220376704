import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import Modal from "react-modal";
import { Menu } from "../../components/Menu/Menu";
import configPost from "../../utils/configPost";
import configPut from "../../utils/configPut";
import uri from "../../utils/uri";
import { useAlert } from "react-alert";
import { Watch } from "react-loader-spinner";
import Header from "../../components/Header/Header";
import configAuthPost from "../../utils/configAuthPost";
import AuthContext from "../../context/auth/authContext";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px"
  }
};

const Workers = () => {
  let { pageNumber } = useParams();
  const authContext = useContext(AuthContext);
  const { token } = authContext;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [tab, setTab] = useState("analytics");
  const [modalTab, setModalTab] = useState(2);
  const [check, setCheck] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [pagination, setPagination] = useState({
    totalPages: 1,
    currentPage: 1
  });

  const alert = useAlert();
  const changeTab = tab => {
    setTab(tab);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const blockWorker = () => {
    setCheck(!check);
  };
  // Редактирование сотруника
  const [worker, setWorker] = useState({
    surname: "",
    name: "",
    email: "",
    phone: ""
  });

  const [changingWorkerLoader, setChangingWokerLoader] = useState(false);

  const { surname, name, email, phone } = worker;

  const onChangeWoker = e => {
    setWorker({ ...worker, [e.target.name]: e.target.value.trim() });
  };

  const openWorker = workerFields => {
    setCheck(workerFields.ban);
    setWorker({
      surname: workerFields.surname,
      name: workerFields.name,
      email: workerFields.email,
      phone: workerFields.phone
    });
    setModalTab(2);
    openModal();
  };

  const changeWorker = async e => {
    try {
      e.preventDefault();
      setChangingWokerLoader(true);

      const response = await fetch(
        `${uri}/api/v1/admin/worker/${currentId}`,
        configPut({ surname, name, email, phone, ban: check })
      );
      if (response.status === 200) {
        alert.show("Данные сотрудника были изменены", { closeCopy: "OK" });
        getWorkers();
        setCurrentId("");
        closeModal();
      }
    } catch (error) {
      alert.show(`Произошла ошибка ${error.message}`, { closeCopy: "OK" });
    } finally {
      setChangingWokerLoader(false);
    }
  };

  // Добавление сотрудника
  const [addWorkerFields, setAddWorkerFields] = useState({
    addSurname: "",
    addName: "",
    addEmail: "",
    addPhone: "",
    addPassword: "",
    addRepeatPassword: ""
  });

  const [addWorkerLoading, setAddWorkerLoading] = useState(false);
  const [workers, setWorkers] = useState([]);

  const { addSurname, addName, addEmail, addPhone, addPassword, addRepeatPassword } =
    addWorkerFields;

  const onChangeAddWoker = e => {
    setAddWorkerFields({ ...addWorkerFields, [e.target.name]: e.target.value.trim() });
  };

  const getWorkers = async () => {
    try {
      const response = await fetch(
        `${uri}/api/v1/admin/workers/${pageNumber ? parseInt(pageNumber) : 1}`
      );
      const json = await response.json();
      setPagination({
        currentPage: json.currentPage,
        totalPages: json.totalPages
      });

      if (Array.isArray(json.users)) {
        setWorkers(json.users);
      } else {
        return;
      }
    } catch (error) {}
  };

  const addWorker = async e => {
    try {
      e.preventDefault();
      if (addWorkerLoading) return;

      if (addPassword !== addRepeatPassword) {
        return alert.show("Пароль и повторите не совпадают!", { closeCopy: "OK" });
      }
      setAddWorkerLoading(true);
      const response = await fetch(
        `${uri}/api/v1/admin/register`,
        configPost({
          name: addName,
          surname: addSurname,
          phone: addPhone,
          email: addEmail,
          password: addPassword
        })
      );
      if (response.status === 200) {
        alert.show("Сотрудник добавлен!", { closeCopy: "OK" });
        cancelAddWorker();
        getWorkers();
        closeModal();
      }
    } catch (error) {
      alert.show(`Произошла ошибка: ${error.message}`);
    } finally {
      setAddWorkerLoading(false);
    }
  };

  const cancelAddWorker = () => {
    setAddWorkerFields({
      addSurname: "",
      addName: "",
      addEmail: "",
      addPhone: "",
      addPassword: "",
      addRepeatPassword: ""
    });
  };

  const resetPassword = async email => {
    try {
      const response = await fetch(
        `${uri}/api/v1/password/reset`,
        configAuthPost({ email }, token)
      );
      if (response.status === 200) {
        alert.show("Пароль отправлен на email сотруднику!");
        closeModal();
      }
    } catch (error) {
      alert.show("Произошла ошибка, попробуйте позже!");
    }
  };

  useEffect(() => {
    getWorkers();
  }, []);

  return (
    <>
      <Header />
      <main style={{ paddingTop: "38px" }}>
        <div className="container" style={{ display: "flex", paddingBottom: "20px" }}>
          <Menu menuName="workers" />
          <div
            className="right-panel"
            style={{
              backgroundColor: "#fff",
              borderRadius: "5px",
              width: "100%",
              position: "relative"
            }}
          >
            <div>
              <div style={{ paddingBottom: "70px" }}>
                <table>
                  <tr style={{ borderLeft: "none", borderRight: "none" }}>
                    <th>№</th>
                    <th>Имя сотрудника</th>
                    <th>Фамилия сотрудника</th>
                    <th>Email</th>
                    <th>Телефон</th>
                    <th>Статус</th>
                  </tr>
                  {workers.map((item, i) => (
                    <tr
                      onClick={() => {
                        setCurrentId(item._id);
                        openWorker(item);
                      }}
                      style={{ cursor: "pointer" }}
                      key={item._id}
                    >
                      <td style={{ paddingTop: "20px" }}>{i + 1}</td>
                      <td style={{ paddingTop: "20px" }}>{item.name}</td>
                      <td style={{ paddingTop: "20px" }}>{item.surname}</td>
                      <td style={{ paddingTop: "20px" }}>{item.email}</td>
                      <td style={{ paddingTop: "20px" }}>{item.phone}</td>
                      <td style={{ paddingTop: "20px" }}>
                        {item.ban ? "Заблокирован" : "Активен"}
                      </td>
                    </tr>
                  ))}
                </table>
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    borderTopColor: "#e5eaeb",
                    borderTopWidth: "1px",
                    borderTopStyle: "solid"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingTop: "11px",
                      paddingBottom: "11px",
                      paddingLeft: "19px",
                      paddingRight: "19px",
                      alignItems: "center"
                    }}
                  >
                    <div
                      style={{ fontSize: "16px", fontWeight: 500, cursor: "pointer" }}
                      onClick={() => {
                        setModalTab(1);
                        openModal();
                      }}
                    >
                      Добавить сотрудника
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ color: "#75787d", fontSize: "11.25px", marginRight: "8px" }}>
                        Страница
                      </div>
                      <div
                        style={{
                          borderColor: "#eaeeef",
                          borderWidth: "1px",
                          borderStyle: "solid",
                          fontSize: "11.25px",
                          paddingLeft: "13px",
                          paddingRight: "13px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          color: "#000"
                        }}
                      >
                        1
                      </div>
                      <div style={{ fontSize: "11.25px", marginLeft: "6px", color: "#75787d" }}>
                        из
                      </div>
                      <div style={{ fontSize: "11.25px", marginLeft: "6px", color: "#75787d" }}>
                        {pagination.totalPages}
                      </div>
                      {/* <div style={{ marginLeft: "5px" }}>
                        <img
                          style={{ width: "12px", height: "12px" }}
                          src="/img/arrow-right.png"
                          alt="arrow-right"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Right panel ends */}
          </div>
        </div>
      </main>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        {modalTab === 1 ? (
          <div style={{ minWidth: "328px", position: "relative" }}>
            <div
              style={{ position: "absolute", right: "16px", top: "16px", cursor: "pointer" }}
              onClick={closeModal}
            >
              <img src="/img/close.png" alt="close" />
            </div>
            <form style={{ padding: "25px" }} onSubmit={addWorker}>
              <div style={{ marginBottom: "16px" }}>
                <div style={{ fontSize: "11.25px", color: "#7f7d76", fontWeight: 500 }}>
                  Имя сотрудника
                </div>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    backgroundColor: "#f4f6f9",
                    borderRadius: "5px",
                    marginTop: "8px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    outlineColor: "#e4f1fa",
                    fontSize: "11.25px"
                  }}
                  required
                  value={addName}
                  name="addName"
                  onChange={onChangeAddWoker}
                />
              </div>
              {/* First ends */}
              <div style={{ marginBottom: "16px" }}>
                <div style={{ fontSize: "11.25px", color: "#7f7d76", fontWeight: 500 }}>
                  Фамилия сотрудника
                </div>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    backgroundColor: "#f4f6f9",
                    borderRadius: "5px",
                    marginTop: "8px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    outlineColor: "#e4f1fa",
                    fontSize: "11.25px"
                  }}
                  required
                  name="addSurname"
                  onChange={onChangeAddWoker}
                  value={addSurname}
                />
              </div>
              {/* Second ends */}
              <div style={{ marginBottom: "16px" }}>
                <div style={{ fontSize: "11.25px", color: "#7f7d76", fontWeight: 500 }}>Email</div>
                <input
                  type="email"
                  style={{
                    width: "100%",
                    backgroundColor: "#f4f6f9",
                    borderRadius: "5px",
                    marginTop: "8px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    outlineColor: "#e4f1fa",
                    fontSize: "11.25px"
                  }}
                  required
                  name="addEmail"
                  onChange={onChangeAddWoker}
                  value={addEmail}
                />
              </div>
              <div style={{ marginBottom: "16px" }}>
                <div style={{ fontSize: "11.25px", color: "#7f7d76", fontWeight: 500 }}>
                  Телефон
                </div>
                <input
                  required
                  type="text"
                  style={{
                    width: "100%",
                    backgroundColor: "#f4f6f9",
                    borderRadius: "5px",
                    marginTop: "8px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    outlineColor: "#e4f1fa",
                    fontSize: "11.25px"
                  }}
                  name="addPhone"
                  onChange={onChangeAddWoker}
                  value={addPhone}
                />
              </div>
              <div style={{ marginBottom: "16px" }}>
                <div style={{ fontSize: "11.25px", color: "#7f7d76", fontWeight: 500 }}>
                  Придумайте парль
                </div>
                <input
                  required
                  type="text"
                  style={{
                    width: "100%",
                    backgroundColor: "#f4f6f9",
                    borderRadius: "5px",
                    marginTop: "8px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    outlineColor: "#e4f1fa",
                    fontSize: "11.25px"
                  }}
                  name="addPassword"
                  onChange={onChangeAddWoker}
                  value={addPassword}
                />
              </div>
              <div style={{ marginBottom: "16px" }}>
                <div style={{ fontSize: "11.25px", color: "#7f7d76", fontWeight: 500 }}>
                  Повторите парль
                </div>
                <input
                  required
                  type="text"
                  style={{
                    width: "100%",
                    backgroundColor: "#f4f6f9",
                    borderRadius: "5px",
                    marginTop: "8px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    outlineColor: "#e4f1fa",
                    fontSize: "11.25px"
                  }}
                  name="addRepeatPassword"
                  onChange={onChangeAddWoker}
                  value={addRepeatPassword}
                />
              </div>
              <div style={{ display: "flex" }}>
                <button
                  style={{
                    backgroundColor: "#7db766",
                    color: "#fff",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "14px",
                    paddingRight: "14px",
                    borderRadius: "5px",
                    fontSize: "12.5px",
                    marginRight: "13px",
                    minWidth: "85px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center"
                  }}
                  type="submit"
                >
                  {addWorkerLoading ? (
                    <Watch
                      color="#fff"
                      height={20}
                      width={20}
                      visible={true}
                      style={{
                        display: "flex",
                        justifyContent: "center"
                      }}
                    />
                  ) : (
                    "Добавить"
                  )}
                </button>
                <button style={{ fontSize: "12.5px" }} onClick={cancelAddWorker}>
                  Отменить
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div style={{ minWidth: "328px", position: "relative" }}>
            <div
              style={{ position: "absolute", right: "16px", top: "16px", cursor: "pointer" }}
              onClick={closeModal}
            >
              <img src="/img/close.png" alt="close" />
            </div>
            <form style={{ padding: "25px" }} onSubmit={changeWorker}>
              <div style={{ marginBottom: "16px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "12.5px",
                    color: "#000",
                    marginBottom: "20px",
                    cursor: "pointer"
                  }}
                  onClick={blockWorker}
                >
                  <div
                    style={{
                      width: "16px",
                      height: "16px",
                      backgroundColor: check ? "#3f6abb" : "#f7f8f8",
                      borderColor: "#e4e4e4",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      marginRight: "9px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    {check && <img src="/img/check.png" alt="check" />}
                  </div>{" "}
                  Заблокировать
                </div>

                <div style={{ fontSize: "11.25px", color: "#7f7d76", fontWeight: 500 }}>
                  Имя сотрудника
                </div>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    backgroundColor: "#f4f6f9",
                    borderRadius: "5px",
                    marginTop: "8px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    outlineColor: "#e4f1fa",
                    fontSize: "11.25px"
                  }}
                  name="name"
                  required
                  value={name}
                  onChange={onChangeWoker}
                />
              </div>
              {/* First ends */}
              <div style={{ marginBottom: "16px" }}>
                <div style={{ fontSize: "11.25px", color: "#7f7d76", fontWeight: 500 }}>
                  Фамилия сотрудника
                </div>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    backgroundColor: "#f4f6f9",
                    borderRadius: "5px",
                    marginTop: "8px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    outlineColor: "#e4f1fa",
                    fontSize: "11.25px"
                  }}
                  name="surname"
                  required
                  value={surname}
                  onChange={onChangeWoker}
                />
              </div>
              {/* Second ends */}
              <div style={{ marginBottom: "16px" }}>
                <div style={{ fontSize: "11.25px", color: "#7f7d76", fontWeight: 500 }}>
                  Email (для восстановления пароля)
                </div>
                <input
                  type="email"
                  style={{
                    width: "100%",
                    backgroundColor: "#f4f6f9",
                    borderRadius: "5px",
                    marginTop: "8px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    outlineColor: "#e4f1fa",
                    fontSize: "11.25px"
                  }}
                  name="email"
                  required
                  value={email}
                  onChange={onChangeWoker}
                />
              </div>
              <div style={{ marginBottom: "16px" }}>
                <div style={{ fontSize: "11.25px", color: "#7f7d76", fontWeight: 500 }}>
                  Телефон
                </div>
                <input
                  type="text"
                  style={{
                    width: "100%",
                    backgroundColor: "#f4f6f9",
                    borderRadius: "5px",
                    marginTop: "8px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    outlineColor: "#e4f1fa",
                    fontSize: "11.25px"
                  }}
                  name="phone"
                  required
                  value={phone}
                  onChange={onChangeWoker}
                />
              </div>

              <div
                style={{
                  minWidth: "118.21px",
                  backgroundColor: "#f4f6f9",
                  borderRadius: "5px",
                  marginTop: "8px",
                  paddingTop: "9px",
                  paddingBottom: "9px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  outlineColor: "#e4f1fa",
                  fontSize: "11.25px",
                  width: "min-content",
                  cursor: "pointer"
                }}
                onClick={() => {
                  resetPassword(email);
                }}
              >
                Сбросить пароль
              </div>
              {/* <div style={{ color: "424346", fontSize: "11.25px" }}>Пароль сброшен</div> */}
              <div style={{ display: "flex", marginTop: "30px" }}>
                <button
                  style={{
                    backgroundColor: "#7db766",
                    color: "#fff",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "14px",
                    paddingRight: "14px",
                    borderRadius: "5px",
                    fontSize: "12.5px",
                    marginRight: "13px",
                    minWidth: "85px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center"
                  }}
                  type="submit"
                >
                  {changingWorkerLoader ? (
                    <Watch
                      color="#fff"
                      height={20}
                      width={20}
                      visible={true}
                      style={{
                        display: "flex",
                        justifyContent: "center"
                      }}
                    />
                  ) : (
                    "Сохранить"
                  )}
                </button>
                {/* <button style={{ fontSize: "12.5px" }}>Отменить</button>  */}
              </div>
            </form>
          </div>
        )}
      </Modal>
    </>
  );
};

export default Workers;
