import { useContext, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import AuthContext from "../context/auth/authContext";

const PrivateRoutes = () => {
  const authContext = useContext(AuthContext);
  const { token, loadUser } = authContext;
  useEffect(() => {
    if (token) {
      loadUser();
    }
  }, []);

  return token ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
