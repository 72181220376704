import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Watch } from "react-loader-spinner";
import uri from "../utils/uri";
import { useAlert } from "react-alert";
import configPost from "../utils/configPost";
import AuthContext from "../context/auth/authContext";

const Login = () => {
  const authContext = useContext(AuthContext);
  const alert = useAlert();
  const { token, loginESign, loadUser, admin } = authContext;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [flagStay, setFlagStay] = useState(false);
  const [error, setError] = useState(false);
  const [userForm, setUserForm] = useState({
    email: "",
    password: ""
  });
  const { email, password } = userForm;

  const onChange = e => {
    setUserForm({ ...userForm, [e.target.name]: e.target.value.trim() });
  };

  const stayInSystem = () => {
    setFlagStay(!flagStay);
  };

  const resetFields = () => {
    setUserForm({ email: "", password: "" });
  };

  const onSubmit = async e => {
    try {
      e.preventDefault();
      setLoading(true);
      const response = await fetch(`${uri}/api/v1/admin/login`, configPost({ email, password }));
      const json = await response.json();
      if (response.status === 200) {
        loginESign(json);
        if (json.role === "admin") {
          navigate("/admin/analytics", { replace: true });
        } else {
          navigate("/admin/announcements/1", { replace: true });
        }

        setError(false);
      }
      if (response.status === 201) {
        resetFields();
        setError(true);
      }
      if (response.status === 404) {
        resetFields();
        setError(true);
      }
    } catch (error) {
      setUserForm({
        email: "",
        password: ""
      });
      alert.show(`Произошла ошибка ${error.message}`, { closeCopy: "OK" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const check = async () => {
      if (token) {
        await loadUser();
        if (admin !== null && admin.role === "admin") {
          navigate("/admin/analytics", { replace: true });
        }
        if (admin !== null && admin.role === "user") {
          navigate("/admin/announcements", { replace: true });
        }
      }
    };
    check();
  }, []);

  return (
    <main className="login-main">
      <div className="container">
        <div className="container-inner">
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              paddingLeft: "84px",
              paddingRight: "178px",
              backgroundColor: "#f5f5f5"
            }}
          >
            <div className="login-logo"></div>
          </div>
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              paddingLeft: "84px",
              paddingRight: "178px",
              backgroundColor: "#fff"
            }}
          >
            <div className="login-form">
              <h2
                style={{
                  fontSize: "16.67px",
                  fontWeight: 500,
                  marginBottom: "27px",
                  textAlign: "center"
                }}
              >
                Добро пожаловать!
              </h2>
              <form onSubmit={onSubmit}>
                <div>
                  <div
                    style={{
                      fontSize: "10.42px",
                      fontWeight: 500,
                      color: "#000",
                      marginBottom: "5px"
                    }}
                  >
                    Email
                  </div>
                  <div>
                    <input
                      type="text"
                      className="login-input"
                      style={error ? { borderColor: "#e34543" } : { borderColor: "#c0c6cd" }}
                      value={email}
                      name="email"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div style={{ marginTop: "12px" }}>
                  <div
                    style={{
                      fontSize: "10.42px",
                      fontWeight: 500,
                      color: "#000",
                      marginBottom: "5px"
                    }}
                  >
                    Пароль
                  </div>
                  <div>
                    <input
                      name="password"
                      type="password"
                      className="login-input"
                      style={error ? { borderColor: "#e34543" } : { borderColor: "#c0c6cd" }}
                      value={password}
                      onChange={onChange}
                    />
                  </div>
                  {error && (
                    <div
                      style={{
                        display: "flex",
                        fontSize: "10.42px",
                        color: "#dc1714",
                        alignItems: "center",
                        marginTop: "8px"
                      }}
                    >
                      <div style={{ marginRight: "7px" }}>
                        <img src="/img/error-icon.png" alt="error" />
                      </div>
                      Вы ввели неверный логин или пароль
                    </div>
                  )}
                </div>
                <div style={{ display: "flex", marginTop: "12px", alignItems: "center" }}>
                  {!flagStay ? (
                    <div
                      style={{
                        marginRight: "6px",
                        width: "15px",
                        height: "15px",
                        backgroundColor: "#f7f8f8"
                      }}
                      className="square-login"
                      onClick={stayInSystem}
                    ></div>
                  ) : (
                    <div
                      style={{
                        marginRight: "6px",
                        width: "15px",
                        height: "15px"
                      }}
                      className="square-login_blue"
                      onClick={stayInSystem}
                    >
                      <img
                        src="/img/check.png"
                        alt="check"
                        style={{ width: "8px", height: "6px" }}
                      />
                    </div>
                  )}
                  <div style={{ fontSize: "10.42px", color: "#363535" }}>Оставаться в системе</div>
                </div>
                {loading ? (
                  <div
                    style={{
                      marginTop: "22px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minWidth: "239px",
                      backgroundColor: "#3f6abb",
                      height: "35px",
                      borderRadius: "5px"
                    }}
                  >
                    <Watch
                      color="#fff"
                      height={24}
                      width={24}
                      visible={true}
                      style={{
                        display: "flex",
                        justifyContent: "center"
                      }}
                    />
                  </div>
                ) : (
                  <div style={{ marginTop: "22px", minWidth: "239px" }}>
                    <input
                      type="submit"
                      value="Войти"
                      style={{
                        backgroundColor: "#3f6abb",
                        height: "35px",
                        fontSize: "12.5px",
                        color: "#fff",
                        borderRadius: "5px"
                      }}
                    />
                  </div>
                )}
                <div style={{ display: "flex", justifyContent: "center", marginTop: "12px" }}>
                  <button style={{ fontSize: "10.42px", color: "#3f6abb", fontWeight: "500" }}>
                    Я забыл пароль
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
