import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

// importing page routes
import Login from "./pages/Login";
import Analytics from "./pages/admin/Analytics";
import Announcements from "./pages/admin/Announcements";
import Workers from "./pages/admin/Workers";
import Control from "./pages/admin/Control";
import Category from "./pages/admin/content/Category";
import Subcategory from "./pages/admin/content/Subcategory";
import Instaction from "./pages/admin/content/Instraction";

import PrivateRoutes from "./utils/PrivateRoutes";
import PrivateRoutesAdmin from "./utils/PrivateRoutesAdmin";

// importing Context
import AuthState from "./context/auth/AuthState";

const App = () => {
  return (
    <AuthState>
      <Router>
        <Routes>
          <Route element={<Login />} path="/" />
          {/* Admin routes */}
          <Route element={<PrivateRoutes />}>
            <Route element={<Announcements />} path="/admin/announcements/:pageNumber" />
          </Route>
          <Route element={<PrivateRoutesAdmin />}>
            <Route element={<Analytics />} path="/admin/analytics" />
            <Route element={<Announcements />} path="/admin/announcements/:pageNumber" />
            <Route element={<Workers />} path="/admin/workers/:pageNumber" />
            <Route element={<Category />} path="/admin/control/category" />
            <Route element={<Subcategory />} path="/admin/control/subcategory" />
            <Route element={<Instaction />} path="/admin/control/instraction" />
          </Route>
        </Routes>
      </Router>
    </AuthState>
  );
};

export default App;
