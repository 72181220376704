import { useState, useContext, useEffect } from "react";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Menu } from "../../../components/Menu/Menu";
import Header from "../../../components/Header/Header";
import configAuthPost from "../../../utils/configAuthPost";
import configDelete from "../../../utils/configDelete";
import configAuthPut from "../../../utils/configAuthPut";
import uri from "../../../utils/uri";
import AuthContext from "../../../context/auth/authContext";
import { Watch } from "react-loader-spinner";
import { useAlert } from "react-alert";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px"
  }
};

const Category = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const { token } = authContext;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [openedCategory, setOpenedCategory] = useState({
    category: "",
    id: ""
  });

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [select, setSelect] = useState(location.pathname.split("/")[3]);
  // Добавить категорию
  const [category, setCategory] = useState("");
  const [categoryAddLoading, setCategoryLoading] = useState(false);
  const [categoryDeleteLoading, setCategoryDeleteLoading] = useState(false);

  // For edit
  const addCategory = async e => {
    try {
      e.preventDefault();
      if (categoryAddLoading) return;
      setCategoryLoading(true);
      if (!category) return alert.show("Заполните поле категории", { closeCopy: "OK" });
      const response = await fetch(
        `${uri}/api/v1/admin/add/category`,
        configAuthPost({ category }, token)
      );
      if (response.status === 409) {
        alert.show("Данная категория уже существует!", { closeCopy: "OK" });
        setCategory("");
      }
      if (response.status === 200) {
        alert.show("Категория добавлена!", { closeCopy: "OK" });
        setCategory("");
        closeModal();
        getCategories();
      }
    } catch (error) {
      alert.show(`Произошла ошибка, ${error.message}`);
    } finally {
      setCategoryLoading(false);
    }
  };

  const getCategories = async () => {
    try {
      const response = await fetch(`${uri}/api/v1/admin/get/categories`);

      if (response.status === 200) {
        const json = await response.json();

        if (Array.isArray(json)) setCategories(json);
      }
    } catch (error) {}
  };

  const deleteCategory = async id => {
    try {
      if (categoryDeleteLoading) return;
      setCategoryDeleteLoading(true);
      const response = await fetch(
        `${uri}/api/v1/admin/delete/category/${id}`,
        configDelete(token)
      );
      if (response.status === 200) {
        const json = await response.json();
        if (json.msg === "Category removed") {
          alert.show("Категория удалена!", { closeCopy: "OK" });
          getCategories();
          closeModal();
        }
      }
    } catch (error) {
      alert.show("Произошла ошибка при удалении!", { closeCopy: "OK" });
    } finally {
      setCategoryDeleteLoading(false);
    }
  };

  const [editCategoryLoading, setEditCategoryLoading] = useState(false);

  const editCategory = async e => {
    try {
      e.preventDefault();
      setEditCategoryLoading(true);
      const response = await fetch(
        `${uri}/api/v1/admin/edit/category/${openedCategory.id}`,
        configAuthPut({ category: openedCategory.category }, token)
      );
      const json = await response.json();
      console.log(json);
      if (response.status === 200) {
        alert.show("Изменения были внесены", { closeCopy: "OK" });
        getCategories();
        setOpenedCategory({
          category: "",
          id: ""
        });
        closeModal();
      }
    } catch (error) {
      alert.show("Произошла ошибка 23", { closeCopy: "OK" });
    } finally {
      setEditCategoryLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <Header />
      <main style={{ paddingTop: "38px" }}>
        <div className="container" style={{ display: "flex", paddingBottom: "20px" }}>
          <Menu menuName="control" />
          <div
            className="right-panel"
            style={{
              backgroundColor: "#fff",
              borderRadius: "5px",
              width: "100%",
              position: "relative"
            }}
          >
            <div>
              <div style={{ paddingBottom: "70px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", padding: "18px" }}>
                  <select
                    style={{
                      border: "none",
                      outline: "none",
                      backgroundColor: "#eaeffd",
                      padding: "10px",
                      borderRadius: "5px"
                    }}
                    onChange={e => {
                      if (e.target.value === "subcategory") navigate("/admin/control/subcategory");
                      if (e.target.value === "category") navigate("/admin/control/category");
                      if (e.target.value === "instraction") navigate("/admin/control/instraction");
                    }}
                  >
                    <option value="category">Категории</option>
                    <option value="instraction">Инструкции</option>
                    <option value="subcategory">Подкатегории</option>
                  </select>

                  <div
                    style={{
                      backgroundColor: "#28aeda",
                      borderRadius: "5px",
                      color: "#fff",
                      fontSize: "12.5px",
                      fontWeight: 500,
                      height: "33px",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingBottom: "5px",
                      paddingTop: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer"
                    }}
                    onClick={openModal}
                  >
                    Добавить категорию
                  </div>
                </div>

                {/* Таблица категории начинается */}
                {select === "category" || select === "editcategory" ? (
                  <table>
                    <thead>
                      <tr style={{ borderLeft: "none", borderRight: "none" }}>
                        <th>№</th>
                        <th>Категория</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categories.map((item, i) => (
                        <tr
                          onClick={() => {
                            setSelect("editcategory");
                            setOpenedCategory({
                              category: item.category,
                              id: item._id
                            });
                            openModal();
                          }}
                          key={item._id}
                        >
                          <td style={{ paddingTop: "20px" }}>{i + 1}</td>
                          <td style={{ paddingTop: "20px" }}>{item.category}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : null}
                {/* Таблица категории заканчивается */}

                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    borderTopColor: "#e5eaeb",
                    borderTopWidth: "1px",
                    borderTopStyle: "solid"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingTop: "11px",
                      paddingBottom: "11px",
                      paddingLeft: "19px",
                      paddingRight: "19px",
                      alignItems: "center"
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ color: "#75787d", fontSize: "11.25px", marginRight: "8px" }}>
                        Страница
                      </div>
                      <div
                        style={{
                          borderColor: "#eaeeef",
                          borderWidth: "1px",
                          borderStyle: "solid",
                          fontSize: "11.25px",
                          paddingLeft: "13px",
                          paddingRight: "13px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          color: "#000"
                        }}
                      >
                        1
                      </div>
                      <div style={{ fontSize: "11.25px", marginLeft: "6px", color: "#75787d" }}>
                        из
                      </div>
                      <div
                        style={{ fontSize: "11.25px", marginLeft: "6px", color: "#75787d" }}
                        onClick={() => {
                          console.log(select);
                        }}
                      >
                        11
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Right panel ends */}
          </div>
        </div>
      </main>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <div style={{ minWidth: "328px", position: "relative" }}>
          <div
            style={{ position: "absolute", right: "16px", top: "16px", cursor: "pointer" }}
            onClick={closeModal}
          >
            <img src="/img/close.png" alt="close" />
          </div>
          {/* Добавить категорию модуль */}

          {select === "editcategory" && (
            <div style={{ padding: "19px" }}>
              <div style={{ fontSize: "11.25px", color: "#7f7d76" }}>Категория</div>
              <form onSubmit={e => editCategory(e)}>
                <div>
                  <input
                    type="text"
                    style={{
                      padding: "5px",
                      borderColor: "#e4f1fa",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      fontSize: "11.25px",
                      fontWeight: 500,
                      borderRadius: "5px",
                      marginTop: "16px"
                    }}
                    value={openedCategory.category}
                    onChange={e => {
                      setOpenedCategory({ ...openedCategory, [e.target.name]: e.target.value });
                    }}
                    name="category"
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center", paddingTop: "30px" }}>
                  <button
                    style={{
                      marginRight: "17px",
                      color: "#fff",
                      backgroundColor: "#7db766",
                      borderRadius: "5px",
                      height: "24px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      fontSize: "12.5px",
                      minWidth: "78px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                    type="submit"
                  >
                    {editCategoryLoading ? (
                      <Watch
                        color="#fff"
                        height={12}
                        width={12}
                        visible={true}
                        style={{
                          display: "flex",
                          justifyContent: "center"
                        }}
                      />
                    ) : (
                      "Сохранить"
                    )}
                  </button>
                  <div
                    style={{ fontSize: "12.5px", cursor: "pointer" }}
                    onClick={() => {
                      deleteCategory(openedCategory.id);
                    }}
                  >
                    Удалить
                  </div>
                </div>
              </form>
            </div>
          )}

          {/* Добавить категорию конец модуль */}
          {/* Добавить категорию модуль */}
          {select === "category" && (
            <div style={{ padding: "19px" }}>
              <div style={{ fontSize: "11.25px", color: "#7f7d76" }}>Новая категория</div>
              <form onSubmit={addCategory}>
                <div>
                  <input
                    type="text"
                    style={{
                      padding: "5px",
                      borderColor: "#e4f1fa",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      fontSize: "11.25px",
                      fontWeight: 500,
                      borderRadius: "5px",
                      marginTop: "16px"
                    }}
                    required
                    value={category}
                    onChange={e => setCategory(e.target.value)}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center", paddingTop: "30px" }}>
                  <button
                    style={{
                      marginRight: "17px",
                      color: "#fff",
                      backgroundColor: "#7db766",
                      borderRadius: "5px",
                      height: "24px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      fontSize: "12.5px",
                      minWidth: "78px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                    type="submit"
                  >
                    {categoryAddLoading ? (
                      <Watch
                        color="#fff"
                        height={12}
                        width={12}
                        visible={true}
                        style={{
                          display: "flex",
                          justifyContent: "center"
                        }}
                      />
                    ) : (
                      "Добавить"
                    )}
                  </button>
                  <div
                    style={{ fontSize: "12.5px", cursor: "pointer" }}
                    onClick={() => setCategory("")}
                  >
                    Отменить
                  </div>
                </div>
              </form>
            </div>
          )}
          {/* Добавить категорию конец модуль */}
        </div>
      </Modal>
    </>
  );
};

export default Category;
