const configPut = fields => {
  const config = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(fields)
  };

  return config;
};

export default configPut;
