import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";

export const Menu = ({ menuName }) => {
  const authContext = useContext(AuthContext);
  const { admin } = authContext;
  const navigate = useNavigate();
  return (
    <div className="left-panel" style={{ marginRight: "14px" }}>
      {admin?.role === "admin" && (
        <div
          onClick={() => navigate("/admin/analytics")}
          style={{
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingTop: "7px",
            paddingBottom: "7px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "204px",
            cursor: "pointer"
          }}
          className={menuName === "analytics" ? "analytica-active" : ".analytica-disactive"}
        >
          <div
            style={
              menuName === "analytics"
                ? { display: "flex", alignItems: "center", fontWeight: 700 }
                : { display: "flex", alignItems: "center" }
            }
          >
            <div style={{ marginRight: "10px", fontSize: "12.5px", color: "#4d4c4c" }}>
              <img src="/img/analitica-icon.png" alt="analytic" />
            </div>
            Аналитика
          </div>
          <div
            style={{
              width: "5px",
              height: "5px",
              borderRadius: "50%",
              backgroundColor: "#000"
            }}
          ></div>
        </div>
      )}
      {/* Announcements */}
      <div
        onClick={() => navigate("/admin/announcements/1")}
        style={{
          paddingLeft: "12px",
          paddingRight: "12px",
          paddingTop: "7px",
          paddingBottom: "7px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "204px",
          cursor: "pointer"
        }}
        className={menuName === "announcements" ? "analytica-active" : ".analytica-disactive"}
      >
        <div
          style={
            menuName === "announcements"
              ? { display: "flex", alignItems: "center", fontWeight: 700 }
              : { display: "flex", alignItems: "center" }
          }
        >
          <div style={{ marginRight: "10px", fontSize: "12.5px", color: "#4d4c4c" }}>
            <img src="/img/announce-icon.png" alt="analytic" />
          </div>
          Обращения
        </div>
        <div
          style={{
            width: "5px",
            height: "5px",
            borderRadius: "50%",
            backgroundColor: "#000"
          }}
        ></div>
      </div>
      {/* Workers */}
      {admin?.role === "admin" && (
        <div
          onClick={() => navigate("/admin/workers/1")}
          style={{
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingTop: "7px",
            paddingBottom: "7px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "204px",
            cursor: "pointer"
          }}
          className={menuName === "workers" ? "analytica-active" : ".analytica-disactive"}
        >
          <div
            style={
              menuName === "workers"
                ? { display: "flex", alignItems: "center", fontWeight: 700 }
                : { display: "flex", alignItems: "center" }
            }
          >
            <div style={{ marginRight: "10px", fontSize: "12.5px", color: "#4d4c4c" }}>
              <img src="/img/worker-icon.png" alt="analytic" />
            </div>
            Сотрудники
          </div>
          <div
            style={{
              width: "5px",
              height: "5px",
              borderRadius: "50%",
              backgroundColor: "#000"
            }}
          ></div>
        </div>
      )}
      {/* Controling */}
      {admin?.role === "admin" && (
        <div
          onClick={() => navigate("/admin/control/instraction")}
          style={{
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingTop: "7px",
            paddingBottom: "7px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "204px",
            cursor: "pointer"
          }}
          className={menuName === "control" ? "analytica-active" : ".analytica-disactive"}
        >
          <div
            style={
              menuName === "control"
                ? { display: "flex", alignItems: "center", fontWeight: 700 }
                : { display: "flex", alignItems: "center" }
            }
          >
            <div style={{ marginRight: "10px", fontSize: "12.5px", color: "#4d4c4c" }}>
              <img src="/img/controling-icon.png" alt="controling" />
            </div>
            Управление
          </div>
          <div
            style={{
              width: "5px",
              height: "5px",
              borderRadius: "50%",
              backgroundColor: "#000"
            }}
          ></div>
        </div>
      )}
    </div>
  );
};
