const configAuthPost = token => {
  const config = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    }
  };

  return config;
};

export default configAuthPost;
