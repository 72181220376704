import { useContext } from "react";
import AuthContext from "../../context/auth/authContext";

const Header = () => {
  const authContext = useContext(AuthContext);
  const { logout, admin } = authContext;
  return (
    <header style={{ backgroundColor: "#fff", paddingTop: "10px", paddingBottom: "10px" }}>
      <div className="container">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <img src="/img/logo.png" alt="logo" style={{ width: "93px", height: "55px" }} />
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{ marginRight: "16px", fontSize: "13.75px" }}>
              {admin?.name} {admin?.surname}
            </div>
            <div style={{ cursor: "pointer" }} onClick={logout}>
              <img src="/img/logout-icon.png" alt="logout" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
